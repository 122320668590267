import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
//import { FsService } from '../fs.service';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

//import employeesJson from './../../assets/employees.json';

//import data = require('../../assets/employees.json');

export type Employee = {
	name: string,
	position: string,
	phone_number: string,
	email1: string,
	email2: string,
	img: string
};

@Component({
	selector: 'app-employees',
	templateUrl: './employees.component.html',
	styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

	isMoreEmployees: boolean = false;
	isCollapsed: boolean = true;
	dataJson: any;
	nrOfEmployeesVisible: number = 10;
	collapseEmp: HTMLCollectionOf<Element>;
	employees: Observable<any[]>;
	//employeesArr: Employee[];
	employeesArr: any;

	constructor(private httpService: HttpClient) { }

	ngOnInit() {
		this.httpService.get('../../assets/employees/employees.json').subscribe(
			data => {
				this.dataJson = data as any;	 // FILL THE ARRAY WITH DATA.
				//console.log(this.dataJson.employees);
				this.employeesArr = this.dataJson.employees;
			},
			(err: HttpErrorResponse) => {
				console.log(err.message);
			}
		);
	}

	toggleMoreEmp() {

		//console.log("toggle emp");
		this.isCollapsed = !this.isCollapsed;
		this.collapseEmp = document.getElementsByClassName('collapse-emp')
		if (this.isCollapsed) {
			Array.prototype.forEach.call(this.collapseEmp, function (e) {
				// Do stuff here
				e.style.display = "none";
			});

			/*this.myMenu.style.display = "block";
			console.log("open blaaaaa");*/
		} else {
			Array.prototype.forEach.call(this.collapseEmp, function (e) {
				// Do stuff here
				e.style.display = "block";
			});

		}
	}

}

