import { Component, OnInit } from '@angular/core';
import {TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	menuIsOpen:boolean = false;
	myMenu: HTMLElement;
	supportedLanguages = {"is": "Íslenska", "en": "English"};

	constructor(private translate: TranslateService) {

	}
	
	ngOnInit() { }
	
	changeLang(language: string) {  
		localStorage.setItem('locale', language);  
		this.translate.use(language);  
	}

	toggleBurger() {
	  this.menuIsOpen = !this.menuIsOpen
	}

	closeMenu() {
		this.menuIsOpen = false;
		document.getElementById("myNav").style.width = "0% !important";
		document.getElementById('boboddy').style.position ="relative";
	}

	/* toggle */
	toggleMenu() {
		this.menuIsOpen = !this.menuIsOpen;
		if(this.menuIsOpen) {
			
			document.getElementById("myNav").style.width = "100% !important";
			document.getElementById('boboddy').style.position ="fixed";

			/*this.myMenu.style.display = "block";
			console.log("open blaaaaa");*/
		} else {
			
			document.getElementById("myNav").style.width = "0% !important";
			document.getElementById('boboddy').style.position ="relative";
			/*this.myMenu.style.display = "none";

			console.log("close");*/
		}
		
	}	

}
