import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

	tosData: any;
	tosTitles: any[];
	tosMap = new Map();
	constructor(private translate: TranslateService) { }

	ngOnInit() {
		this.translate.get(['ToS']).subscribe(res => {

			this.tosData = res.ToS;
			//delete this.tosData["Title"];

			/*for (let [key, value] of res) {
				for (let [k, v] of value) {
					if(k == 'T') {
						delete value["T"];
						this.tosMap.set(k, v);
					}
				}
			}

			console.log(typeof(this.tosMap));

			this.tosTitles = [];
			for (var key in this.tosData) {
				if (this.tosData.hasOwnProperty(key)) {
					//console.log(key + ": " + this.tosData[key].T);
					this.tosTitles.push(this.tosData[key].T);
					delete this.tosData[key].T;

				}
			}

			this.tosData.forEach(element => {
				this.tosTitles.push(element.T);
				delete element["T"];
			});

			console.log(this.tosTitles);*/
		});
	}

	isTitle = function (s: string) {
		console.log(s.localeCompare('T') || s.localeCompare("Title"), s)
		
		if(s.localeCompare('T'))
			return true;
		if(s.localeCompare("Title"))
			return true;
		
		return false;
	};

}

