import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';


/* local stuff */
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, DialogIE } from './app.component';


/* landing page components*/
import { EmployeesComponent } from './landing-page/employees/employees.component';
import { FooterComponent } from './landing-page/footer/footer.component';
import { CarouselComponent } from './landing-page/carousel/carousel.component';
import { ContentCardsComponent } from './landing-page/content-cards/content-cards.component';
import { HomeComponent } from './landing-page/home/home.component';
import { NavbarComponent } from './landing-page/navbar/navbar.component';
import { PrivacyPolicyComponent } from './landing-page/privacy-policy/privacy-policy.component';
import { HeaderComponent } from './landing-page/header/header.component';
import { LandingPageTemplateComponent } from './landing-page/landing-page-template/landing-page-template.component';
import { TermsOfServiceComponent } from './landing-page/terms-of-service/terms-of-service.component';
import { MarketingAreaComponent } from './landing-page/marketing-area/marketing-area.component';

/* shared stuff*/
import { ScrollTopComponent } from './shared/scroll-top/scroll-top.component';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { AboutComponent } from './landing-page/about/about.component';


export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
};

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: 'laser.is'
	},
	position: 'bottom-right',
	palette: {
		popup: {
			background: "#323232",
			text: "white",
			link: "white"
		},
		button: {
			background: "var(--gt-light-blue)",
			text: "white",
		}
	},
	theme: "block",
	type: 'info',
	layouts: {
		"my-custom-layout": '{{messagelink}}{{compliance}}'
	},
	elements: {
		messagelink: `
		<h3 style="color: var(--gt-yellow)">Vafrakökur</h3>
		<hr>
		<span id="cookieconsent:desc" class="cc-message">
			{{message}} 
			<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
		</span>
		`,
	},
	content: {
		message: "Þessi vefur notar vefkökur til að tryggja sem besta upplifun þegar hann er heimsóttur og hjálpar okkur við vefgreiningar.",
		dismiss: "Samþykkja",
		cookiePolicyLink: 'Skilmálar',
		cookiePolicyHref: '/privacy-policy'
	}
};

export function firebaseProjectNameGetter() {
	return 'gt-app';
};

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		CarouselComponent,
		ContentCardsComponent,
		ScrollTopComponent,
		HomeComponent,
		NavbarComponent,
		PrivacyPolicyComponent,
		HeaderComponent,		
		EmployeesComponent,		
		LandingPageTemplateComponent,
		TermsOfServiceComponent,
		MarketingAreaComponent,		
		NotfoundComponent,
		AboutComponent,		
		DialogIE,		
	],
	imports: [
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AngularMaterialModule,
		HttpClientModule,
		NgcCookieConsentModule.forRoot(cookieConfig),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	bootstrap: [AppComponent],
	entryComponents: [
		DialogIE ]
})
export class AppModule { }
