import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketing-area',
  templateUrl: './marketing-area.component.html',
  styleUrls: ['./marketing-area.component.scss']
})
export class MarketingAreaComponent implements OnInit {

	imgNames: string[] = [
		'design_port.jpg',
		'port02.jpg',
		'port03.jpg',
		'brake_port.jpg',
		'port05.jpg',

	]
	constructor(private translate: TranslateService) { }

	ngOnInit() {
	}

}
