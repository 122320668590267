import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './shared/notfound/notfound.component';

/* landing page stuff */
import { LandingPageTemplateComponent } from './landing-page/landing-page-template/landing-page-template.component';
import { HomeComponent } from './landing-page/home/home.component';
import { PrivacyPolicyComponent } from './landing-page/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './landing-page/terms-of-service/terms-of-service.component';

import { TranslateModule } from '@ngx-translate/core';



const routes: Routes = [
	{
		path: '', component: LandingPageTemplateComponent, children: [
			{ path: '', component: HomeComponent },
			{ path: 'privacy-policy', component: PrivacyPolicyComponent },
			{ path: 'terms-of-service', component: TermsOfServiceComponent },
		],
		data: { title: 'Geislatækni' }
	},

	/*{ path: 'secret', component: SuperSecretComponent, canActivate: [AuthGuard] },*/
	{ path: '404', component: NotfoundComponent },
	{ path: '**', redirectTo: '/404', pathMatch: 'full' }
];
/*
{ path: '', pathMatch: 'full', redirectTo: 'login' },
{ path: 'login', component: UserProfileComponent },
{ path: 'dashboard', component: UserProfileComponent }
];*/

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [0, 64],
		}),
		TranslateModule
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
