import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';


import {
   MatButtonModule,
   MatToolbarModule,
   MatBadgeModule,
   MatSidenavModule,
   MatListModule,
   MatGridListModule,
   MatFormFieldModule,
   MatInputModule,
   MatSelectModule,
   MatRadioModule,
   MatDatepickerModule,
   MatNativeDateModule,
   MatChipsModule,
   MatTooltipModule,
   MatTableModule,
   MatPaginatorModule,
   MatCardModule,
   MatMenuModule,
   MatSortModule,
   MatDialogModule,
   MatCheckboxModule,
   MatExpansionModule,
   MatTabsModule,
   MatProgressSpinnerModule,
   MatSnackBarModule

} from '@angular/material';


@NgModule({
   imports: [
      CommonModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatGridListModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
	  MatCardModule,
	  MatMenuModule,
	  MatSortModule,
	  DragDropModule,
	  MatDatepickerModule,
	  MatNativeDateModule,
	  LayoutModule,
	  MatDialogModule,
	  MatCheckboxModule,
	  MatExpansionModule,
	  MatTabsModule,
	  MatProgressSpinnerModule,
	  MatSnackBarModule
   ],
   exports: [
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatGridListModule,
      MatInputModule,
      MatFormFieldModule,
      MatSelectModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
	  MatCardModule,
	  MatMenuModule,
	  MatSortModule,
	  DragDropModule,
	  MatDatepickerModule,
	  MatNativeDateModule,
	  LayoutModule,
	  MatDialogModule,
	  MatCheckboxModule,
	  MatExpansionModule,
	  MatTabsModule,
	  MatProgressSpinnerModule,
	  MatSnackBarModule
   ],
   providers: [
		MatDatepickerModule
	]
})

export class AngularMaterialModule { }