import { Component, OnInit } from '@angular/core';
import {TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-notfound',
	templateUrl: './notfound.component.html',
	styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

	imagePath:string;

	constructor(private translate: TranslateService) { 
	
	}

	ngOnInit() { }
/*
	carousel;
	prevButton;
	nextButton;
	cellCount = 6;
	selectedIndex = 0;


	constructor() {

	}

	ngOnInit() {

		this.carousel = document.getElementById('carousel')
		this.prevButton = document.getElementById('previous-button');
		this.nextButton = document.getElementById('next-button');
		this.prevButton.addEventListener('click', () => {
			this.selectedIndex--;
			this.rotateCarousel();
		});

		this.nextButton.addEventListener('click', () => {
			this.selectedIndex++;
			this.rotateCarousel();
		});

	}

	rotateCarousel() {
		var angle = this.selectedIndex / this.cellCount * -360;
		this.carousel.style.transform = 'translateZ(-64.95vw) rotateY(' + angle + 'deg)';
	}
*/

}


