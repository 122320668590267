import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	isIE: boolean;

	constructor(
		private ccService: NgcCookieConsentService,
		public translate: TranslateService,
		public dialog: MatDialog,
		 /*@Inject(PLATFORM_ID) private platformId: Object*/) {
		


		translate.addLangs(['is', 'en']);
		if (localStorage.getItem('locale')) {
			const browserLang = localStorage.getItem('locale');
			translate.use(browserLang.match(/is|en/) ? browserLang : 'is');
		} else {
			localStorage.setItem('locale', 'is');
			translate.setDefaultLang('is');
		}

		/*this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
			if (isPlatformBrowser(this.platformId)) {
			  window.scroll(0, 0);
			}
			
		});*/
	}

	ngOnInit(): void {
		// Internet Explorer 6-11
		this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
		console.log('is IE: ', this.isIE);
		if (this.isIE) {
			setTimeout(() => this.openDialog());
		}
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(DialogIE, {
			width: '400px',
			maxWidth: '80%'
		});
	}
}

@Component({
	selector: 'dialog-ie',
	templateUrl: 'dialogs/dialog-ie.html',
})
export class DialogIE {

	constructor(
		public dialogRef: MatDialogRef<DialogIE>) { }

	onNoClick(): void {
		this.dialogRef.close();
	}

}
